import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const Blog = () => {
  return (
    <>
      <Header />
      What a fak
      <Footer />
    </>
  )
}

export default Blog