import React from 'react'
import './Problem.css'

const Problem = () => {
  return (
    <div className="problem-section">
      <div className="problem-container">

          <h1 className="problem">
            Marketing je bitan ali...
          </h1>
          <h2>
            Vođenje biznisa oduzima ogroman deo vašeg vremena.
          </h2>
          <h3>
            Previše stvari je na vasoj list obeveza, a sve su jako bitne za Vas.
          </h3>

      </div>
    </div>
  )
}

export default Problem