import React from 'react'
import Header from '../../components/Header/Header'
import Hero from '../../sections/Hero/Hero'
import Problem from '../../sections/Problem/Problem'
import Agitate from '../../sections/Agitate/Agitate'
import Solution from '../../sections/Solution/Solution'
import Contact from '../../sections/Contact/Contact'
import CTA from '../../sections/CTA/CTA'
import Footer from '../../components/Footer/Footer'

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Problem />
      <Agitate />
      <Solution />
      <CTA />
      <Contact />
      <Footer /> 
    </div>
  )
}

export default Home