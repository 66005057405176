import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import './Contact.css'
import { toast } from 'react-toastify'

const Contact = () => {
  const form = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")

  const emailID = process.env.REACT_APP_EMAILJS_SERVICE_ID
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  const pubKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

  const sendEmail = (e) => {
    e.preventDefault()

    setIsLoading(true)
    setMessage('')

    emailjs
      .sendForm( 
        emailID, 
        templateID,
        form.current,
        {
          publicKey: pubKey
        }
      )
      .then(
        (result) => {
          // console.log(result.text)
          // console.log('Success')

          // setMessage("Emial sent successfully!")
          setIsLoading(false)
          form.current.reset()

          toast.success('Email je ušpesno poslat', { 
            position: "bottom-right",
            autoClose: 3000 
          })
        },
        (error) => {
          // console.log(error)
          // setMessage('Failed to send mail. Please try again later.')
          setIsLoading(true)
          form.current.reset()

          toast.error('Došlo je do greske!', { 
            position: "bottom-right",
            autoClose: 3000 
          })
        }
      )
  }

  return (
    <div id="contact" className="contact-section">
      <div className="contact-container">

          <div className="contact-left">
            <h1>Želite da sarađujemo?</h1>
          </div>

          <div className="contact-right">
            <form ref={form} onSubmit={sendEmail} className="contact-form" autoComplete="off">
              <input type="text" name="name" placeholder="Ime*" required/>
              <input type="email" name="email" placeholder="Email*" required/>
              <textarea name="message" placeholder="Poruka*" required />
              <button type="submit" className="form-btn" disabled={isLoading}>
                { isLoading ? 'Šalje se' : 'Kontaktirajte nas' } 
              </button>
            </form>
          </div>

      </div>
    </div>
  )
}

export default Contact