import React from 'react'
import './CTA.css'
import { Link as LinkScroll } from 'react-scroll'

const CTA = () => {
  return (
    <div id="cta" className="cta-section">
      <div className="cta-container">

        <h1>Kontaktirajte nas za besplatnu konsultaciju</h1>
        <LinkScroll to="contact" className="cta-btn" smooth={true} duration={500}>
          Besplatna konsultacija
        </LinkScroll>

      </div>
    </div>
  )
}

export default CTA