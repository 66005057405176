import React from 'react'
import './Agitate.css'

import { FiUser, FiUsers } from "react-icons/fi";
import { MdStorefront } from "react-icons/md";

const Agitate = () => {
  return (
    <div className="agitate-section">
      <div className="agitate-container">

          <h1 className="agitate">
            Kako da poboljšate Vaš <span className="hl">marketing</span>?
          </h1>

          <div className="agitate-cards">
            
            <div className="card">
              <div className="card-ico">
                <FiUser />
              </div>
              <h3>
                Uradite sve sami?
              </h3>
              <p>
                Ako ste prezauzeti vođenjem biznisa, teško ćete izdovjiti vreme da se posvetite martketingu.
              </p>
             
            </div>

            <div className="card">
              <div className="card-ico">
                <FiUsers />
              </div>
              <h3>
                Zaposlite nekog?
              </h3>
              <p>
                Nije lako pronaći kvalitetne ljude.
                Potrebno Vam je više vremena i novca da bi pronašli nekog.
                I uvek ćete zavisiti od te jedne osobe.
              </p>
             
            </div>

            <div className="card">
              <div className="card-ico">
              <MdStorefront />
              </div>
              <h3>
                Angažujte veliku agenciju?
              </h3>
              <p>
              Čak i uz značajan marketinški budžet, postoji rizik da će vašim kampanjama upravljati neiskusni početnici, što može uticati na krajnje rezultate.
              </p>
            </div>

          </div>

      </div>
    </div>
  )
}

export default Agitate