import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
// import axios from 'axios'


const Footer = () => {

  return (
    <footer className="footer-section">
      <div className="footer-container">

        <div className="main-footer">

          <div className="left-footer">
            <h2>Započnite novi projekat sa nama</h2>
            <h3>alek@koyomedia.rs</h3>
            <h3>+381 63 81 01 663</h3>

          </div>

          <div className="right-footer">
            <div className="social-links">
              <Link to="https://www.instagram.com/koyomedia_/" className="social-link">
                <BsInstagram />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=61561675310028" className="social-link">
                <BsFacebook />
              </Link>
              <Link to="https://www.linkedin.com/company/103324457" className="social-link">
                <BsLinkedin />
              </Link>
            </div>

            <h2>Marketing agencija za sve vaše digitalne potrebe.</h2>
            <h3>Nalazimo se u Beogradu.</h3>
          </div>
        </div>

        <div className="under-footer">
          <div className="footer-navigation">
            <div className="nav-link">
              <a href="/#">About us</a>
            </div>
            <div className="nav-link">
              <a href="/#">Linktree</a>
            </div>
            <div className="nav-link">
              <a href="/#">Privacy</a>
            </div>
            <div className="nav-link">
              <a href="/#">Terms Of Service</a>
            </div>
            
          </div>
          <div className="footer-copy">
              <span> &copy; 2024. All Rights Reserved | koyomedia </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer