import React from 'react'
import './Hero.css'

import { Link as LinkScroll } from 'react-scroll'

const Hero = () => {
  return (
    <div id="hero" className="hero-section">
      <div className="hero-container">

          <span>VAŠ RAST - NAŠA STRATEGIJA</span>
          <h1>Veći <span className="hl">rast</span></h1>
          <h1>Više <span className="hl">klijenata</span></h1>
          <h1>Garantujemo</h1>
          <LinkScroll to="contact" className="hero-cta" smooth={true} duration={500}>
            Besplatna konsultacija
          </LinkScroll>
      </div>
    </div>
  )
}

export default Hero