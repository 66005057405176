import React from 'react'
import './Header.css'
import logo from '../../assets/logo.png'
// import { Link } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'

const Header = () => {
  return (
    <div className="main-header">
      <div className="header-container">

        <div className="logo">
          <img src={logo} alt="koyomedia" />
        </div>

        <div className="main-navigation">
          <nav className="navigation">
            <div className="nav-item">
              <LinkScroll to="hero" smooth={true} duration={500}>
                Početna
              </LinkScroll>
            </div>
            <div className="nav-item">
              <LinkScroll to="solution" smooth={true} duration={500}>
                Zašto bas mi?
              </LinkScroll>
            </div>
            {/* <div className="nav-item">
              <Link to="/blog">
                Blog
              </Link>
            </div> */}
            <div className="nav-item">
              <LinkScroll to="contact" smooth={true} duration={500}>
                Kontaktiraj nas
              </LinkScroll>
            </div>
            <div className="nav-item nav-cta">
              <LinkScroll to="contact" smooth={true} duration={500}>
                Besplatna konsultacija
              </LinkScroll>
          </div>
          </nav>
          
        </div>

      </div>
    </div>
  )
}

export default Header