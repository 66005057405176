import React from 'react'
import './Solution.css'
import { FaRegHandshake } from "react-icons/fa";
import { GoTrophy } from "react-icons/go";
import { TbMessageChatbot } from "react-icons/tb";
import { FaRegLightbulb } from "react-icons/fa";

const Solution = () => {
  return (
    <div id="solution" className="solution-section">
      <div className="solution-container">

        <h1 className="solution-header">Zašto baš mi?</h1>

        <div className="card-wraper">
          
          <div className="card">
            <div className="card-ico">
              <FaRegHandshake />
            </div>
            <h2>Partnerstvo</h2> 
            <p>
              Kada vi napredujete, i mi napredujemo.
              To je srž našeg partnerskog odnosa, gde ne snosite sva teret sami.
            </p>
          </div>

          <div className="card">
            <div className="card-ico">
              <GoTrophy />
            </div>
            <h2>Rezultati</h2> 
            <p>
              Na prvom mestu su nam Vaši rezultati.
              Svaki korak koji preduzmemo, svaki plan koji razvijamo, usmeren je ka ostvarenju vaših ciljeva i unapređenju rezultata. 
            </p>
          </div>

        </div>

        <div className="card-wraper">
          
          <div className="card">
            <div className="card-ico">
              <TbMessageChatbot />
            </div>
            <h2>Komunikacija</h2> 
            <p>
              Verujemo da je otvorena komunikacija ključ uspeha. 
              Zajedničkim radom možemo stvoriti inovativna rešenja koja će doneti uspeh Vašem poslovanju.<br/>
              Uvek smo dostupni za sve ideje i sugestije.
            </p>
          </div>

          <div className="card">
            <div className="card-ico">
              <FaRegLightbulb />
            </div>
            <h2>Stručnost</h2> 
            <p>
              Brzi smo i efikasni. 
              Uvek smo korak ispred drugih.
              Zato radimo u branšama u kojima znamo da možemo da postignemo rezultate.
            </p>
          </div>

        </div>


      </div>
    </div>
  )
}

export default Solution